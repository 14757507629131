import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import moment from "moment";
import AppLayout from "../components/AppLayout";
import AppBreacrumb from "../components/AppBreadcrumb";
import FormUser from "../components/FormUser";
import { addUser } from "../api/user";
import AppStore from "../stores/appStore";
import { getEventId, createEventMember } from "../api/event";

const UserCreatePage = () => {
  const { alertShow, setAlertShow, setAlertMsg, setAlertType } = AppStore();
  const { eventId } = useParams();
  const [eventData, setEventData] = useState(null);
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    delete data.confirmPassword;
    //alert(JSON.stringify(data));
    let create = await addUser(data);
    if (create.status === true) {
      setAlertType("success");
      setAlertMsg("บันทึกข้อมูลผู้ใช้งานเรียบร้อย");
      setAlertShow(true);
      //navigate("/user");
    } else {
      setAlertType("error");
      setAlertMsg(create.data);
      setAlertShow(true);
    }
    /*
    let create = await createEventMember(eventId, data);
    if (create.status === true) {
      setAlertMsg("บันทึกข้อมูลผู้เข้าร่วมกิจกรรมเรียบร้อย");
      setAlertShow(true);
      navigate("/event/" + eventId + "/member");
    } else {
    }
    */
  };

  useEffect(() => {}, []);

  return (
    <AppLayout>
      <AppBreacrumb
        data={[
          { label: "ผู้ใช้งาน", link: "/user" },

          { label: "เพิ่มผู้ใช้งาน" },
        ]}
      />

      <div className="app-card">
        <div className="app-card-title">ผู้ใช้งาน</div>
        <FormUser onSubmit={onSubmit} data={null} />
      </div>
    </AppLayout>
  );
};

export default UserCreatePage;
