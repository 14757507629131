import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import moment from "moment";
import AppLayout from "../components/AppLayout";
import AppBreacrumb from "../components/AppBreadcrumb";
import FormRactType from "../components/FormRactType";
import { createEvent } from "../api/event";
import AppStore from "../stores/appStore";
import { getRactTypeById, updateRactType } from "../api/master";

const RactTypeEditPage = () => {
  const location = useLocation();
  let { id } = useParams();
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const navigate = useNavigate();
  const { alertShow, setAlertShow, setAlertType, setAlertMsg } = AppStore();
  const onSubmit = async (data) => {
    //data.eventOpen = 0;
    let create = await updateRactType(editData.ractTypeId, data);
    if (create.status === true) {
      setAlertType("success");
      setAlertMsg("บันทึกข้อมูลประเภทการแข่งขันเรียบร้อย");
      setAlertShow(true);
      navigate("/ract-type");
    } else {
      setAlertType("error");
      setAlertMsg("error");
      setAlertShow(true);
    }
    //alert(JSON.stringify(create));
  };
  const loadData = async (id) => {
    let getData = await getRactTypeById(id);
    if (getData.status === true) {
      setEditData(getData.data.data);
    }
    //alert(JSON.stringify(getDataEvent.data.data));
  };

  useEffect(() => {
    loadData(id);
  }, []);

  return (
    <AppLayout>
      <AppBreacrumb
        data={[
          { label: "ตั้งค่า", link: "/settingt" },
          { label: "ประเภทการแข่งขัน", link: "/ract-type" },
          { label: "แก้ไขประเภทการแข่งขัน" },
        ]}
      />
      <div className="app-card">
        <div className="app-card-title">ประเภทการแข่งขัน</div>
        <FormRactType onSubmit={onSubmit} data={editData} />
      </div>
    </AppLayout>
  );
};

export default RactTypeEditPage;
