import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";

const schema = yup
  .object({
    firstName: yup.string().required("กรุณากรอกข้อมูล"),
    lastName: yup.string().required("กรุณากรอกข้อมูล"),
    email: yup.string().required("กรุณากรอกข้อมูล").email("กรุณาใส่อีเมล"),
    username: yup.string().required("กรุณากรอกข้อมูล"),
    password: yup
      .string()
      .required("กรุณากรอกข้อมูล")
      .min(8, "รหัสผ่านอย่างน้อย 8 ตัวอักษร"),
  })
  .required();

const FormSignUp = ({ onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      username: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                label="ชื่อ"
                error={errors.firstName ? true : false}
                helperText={errors.firstName?.message}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                label="นามสกุล"
                error={errors.lastName ? true : false}
                helperText={errors.lastName?.message}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                label="อีเมล"
                error={errors.email ? true : false}
                helperText={errors.email?.message}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="username"
            control={control}
            render={({ field }) => (
              <TextField
                type="text"
                autoComplete="current-password"
                fullWidth
                label="ชื่อผู้ใช้งาน"
                error={errors.username ? true : false}
                helperText={errors.username?.message}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                type="password"
                autoComplete="current-password"
                fullWidth
                label="รหัสผ่าน"
                error={errors.password ? true : false}
                helperText={errors.password?.message}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" fullWidth variant="contained">
            ลงทะเบียน
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default FormSignUp;
