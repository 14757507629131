import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import moment from "moment";
import AppLayout from "../components/AppLayout";
import AppBreacrumb from "../components/AppBreadcrumb";
import FormUser from "../components/FormUser";
import { editUser, getUserId } from "../api/user";
import AppStore from "../stores/appStore";

const UserEditPage = () => {
  const { userId } = useParams();
  const { alertShow, setAlertShow, setAlertMsg, setAlertType } = AppStore();
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    delete data.confirmPassword;
    if (data.password === "**********") {
      delete data.password;
    }
    //alert(JSON.stringify(data));

    let update = await editUser(userId, data);
    if (update.status === true) {
      setAlertType("success");
      setAlertMsg("บันทึกข้อมูลผู้ใช้งานเรียบร้อย");
      setAlertShow(true);
      navigate("/user");
    } else {
      setAlertType("error");
      setAlertMsg(update.data);
      setAlertShow(true);
    }
  };

  const loadUser = async () => {
    let getUserData = await getUserId(userId);
    //alert(JSON.stringify(getUserData.data.data));
    setUserData(getUserData.data.data);
  };

  useEffect(() => {
    loadUser();
  }, []);

  return (
    <AppLayout>
      <AppBreacrumb
        data={[
          { label: "ผู้ใช้งาน", link: "/user" },
          { label: "แก้ไขผู้ใช้งาน" },
        ]}
      />
      <div className="app-card">
        <div className="app-card-title">แก้ไขข้อมูลผู้ใช้งาน</div>
        <FormUser onSubmit={onSubmit} data={userData} />
      </div>
    </AppLayout>
  );
};

export default UserEditPage;
