import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import moment from "moment";
import AppLayout from "../components/AppLayout";
import AppBreacrumb from "../components/AppBreadcrumb";
import FormEvent from "../components/FormEvent";
import { createEvent } from "../api/event";
import AppStore from "../stores/appStore";
import { getEventId, updateEvent } from "./../api/event";

const EventEditPage = () => {
  const location = useLocation();
  let { id } = useParams();
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const navigate = useNavigate();
  const { alertShow, setAlertShow, setAlertType, setAlertMsg } = AppStore();
  const onSubmit = async (data) => {
    //data.eventOpen = 0;
    data.eventDate = moment(data.eventDate).format("YYYY-MM-DD");
    let create = await updateEvent(editData.eventId, data);
    if (create.status === true) {
      setAlertType("success");
      setAlertMsg("บันทึกข้อมูลกิจกรรมเรียบร้อย");
      setAlertShow(true);
      navigate("/event");
    } else {
      setAlertType("error");
      setAlertMsg("error");
      setAlertShow(true);
    }
    //alert(JSON.stringify(create));
  };
  const loadData = async (id) => {
    let getDataEvent = await getEventId(id);
    if (getDataEvent.status === true) {
      setEditData(getDataEvent.data.data);
    }
    //alert(JSON.stringify(getDataEvent.data.data));
  };

  useEffect(() => {
    loadData(id);
  }, []);

  return (
    <AppLayout>
      <AppBreacrumb
        data={[{ label: "กิจกรรม", link: "/event" }, { label: "แก้ไขกิจกรรม" }]}
      />
      <div className="app-card">
        <div className="app-card-title">กิจกรรม</div>
        <FormEvent onSubmit={onSubmit} data={editData} />
      </div>
    </AppLayout>
  );
};

export default EventEditPage;
