import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import AppLayout from "../components/AppLayout";
import AppBreacrumb from "../components/AppBreadcrumb";
import TbReportEvent from "../components/TbReportEvent";
import { getReportEventData, getReportEventId } from "../api/report";
import EventStore from "../stores/eventStore";
import ReportData from "./../components/ReportData";

const ReportViewPage = () => {
  const { setEvents } = EventStore();
  const navigate = useNavigate();
  const { eventId } = useParams();
  const [report, setReport] = useState();
  const loadData = async () => {
    let data = await getReportEventId(eventId);
    setReport(data.data.data);
  };
  useEffect(() => {
    loadData();
  }, []);
  return (
    <AppLayout>
      {report !== undefined && (
        <AppBreacrumb
          data={[
            { label: "รายงานกิจกรรม", link: "/report" },
            { label: report.eventName },
          ]}
        />
      )}
      <div className="app-card">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            {report !== undefined && (
              <div className="app-card-title">{report.eventName}</div>
            )}
          </Grid>
          <Grid item xs={12} sm={12}>
            {report !== undefined && <ReportData data={report} />}
          </Grid>
        </Grid>
      </div>
    </AppLayout>
  );
};

export default ReportViewPage;
