import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import MuiLink from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import LoginIcon from "@mui/icons-material/Login";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AppLayout from "./../components/AppLayout";
import AppBreacrumb from "./../components/AppBreadcrumb";
import { getEventId } from "./../api/event";

const theme = createTheme();

const EventMemberCreateSuccessPage = () => {
  const navigate = useNavigate();
  const { eventId } = useParams();
  const [eventData, setEventData] = useState(null);
  const loadData = async (id) => {
    let getDataEvent = await getEventId(eventId);
    //alert(JSON.stringify(getDataEvent));
    if (getDataEvent.status === true) {
      setEventData(getDataEvent.data.data);
    }
  };

  useEffect(() => {
    loadData();
  }, []);
  return (
    <AppLayout>
      {eventData !== null && (
        <AppBreacrumb
          data={[
            { label: "กิจกรรม", link: "/event" },
            { label: eventData.eventName, link: "/event" },
            { label: "เพิ่มผู้เข้าร่วมกิจกรรม" },
          ]}
        />
      )}
      <div className="app-card">
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <DirectionsRunIcon sx={{ fontSize: 80 }} />
            <Typography component="h1" variant="h5">
              ผู้เข้าร่วมกิจกรรม
            </Typography>
            <Box sx={{ mt: 4, mb: 2 }}>
              <CheckCircleIcon
                sx={{ fontSize: 180, color: "rgb(76, 175, 80)" }}
              />
            </Box>
            <Typography component="h1" variant="h5">
              บันทึกข้อมูลผู้เข้าร่วมกิจกรรมเรียบร้อย
            </Typography>
          </Box>
        </Container>
      </div>
    </AppLayout>
  );
};

export default EventMemberCreateSuccessPage;
