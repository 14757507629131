import axios from "axios";
import { API_ENDPOINT } from "./../constants/endpoint";

//CURD
export const createEvent = async (data) => {
  try {
    let token = window.sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let event = await axios.post(API_ENDPOINT + "event", data, config);
    return { status: true, data: event.data };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};

export const getEvent = async () => {
  try {
    let token = window.sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let event = await axios.get(API_ENDPOINT + "event", config);
    return { status: true, data: event.data };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};

export const getEventId = async (id) => {
  try {
    let token = window.sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let event = await axios.get(API_ENDPOINT + "event/" + id, config);
    return { status: true, data: event.data };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};

export const updateEvent = async (id, data) => {
  try {
    let token = window.sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let event = await axios.patch(API_ENDPOINT + "event/" + id, data, config);
    return { status: true, data: event.data };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};

export const deleteEvent = async (id) => {
  try {
    let token = window.sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let event = await axios.delete(API_ENDPOINT + "event/" + id, config);
    return { status: true };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};

export const createEventMember = async (eventId, data) => {
  try {
    let token = window.sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let event = await axios.post(
      API_ENDPOINT + "event/" + eventId + "/member",
      data,
      config
    );
    return { status: true, data: event.data };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};

export const getEventMember = async (id) => {
  try {
    let token = window.sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let event = await axios.get(
      API_ENDPOINT + "event/" + id + "/member",
      config
    );
    return { status: true, data: event.data };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};

export const getEventMemberId = async (eventId, id) => {
  try {
    let token = window.sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let event = await axios.get(
      API_ENDPOINT + "event/" + eventId + "/member/" + id,
      config
    );
    return { status: true, data: event.data };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};

export const updateEventMember = async (eventId, id, data) => {
  try {
    let token = window.sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let event = await axios.patch(
      API_ENDPOINT + "event/" + eventId + "/member/" + id,
      data,
      config
    );
    return { status: true, data: event.data };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};

export const createImportMember = async (eventId, data) => {
  try {
    let token = window.sessionStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    let event = await axios.post(
      API_ENDPOINT + "event/" + eventId + "/import",
      data,
      config
    );
    return { status: true, data: event.data };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};

export const getTemplateMember = async (eventId) => {
  try {
    let token = window.sessionStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    let event = await axios.get(
      API_ENDPOINT + "event/" + eventId + "/template",
      config
    );
    return { status: true, data: event.data };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};
//FUNCTION

export const getEventData = async (store) => {
  let data = await getEvent();
  if (data.status === true) {
    store(data.data.data);
  }
};

export const getEventMemberData = async (store, eventId) => {
  let data = await getEventMember(eventId);
  if (data.status === true) {
    store(data.data.data);
  }
};

export const getSearchEventMember = async (id, searchData) => {
  try {
    let searchText = "";
    if (searchData.firstName) {
      searchText += "&firstName=" + searchData.firstName;
    }
    if (searchData.lastName) {
      searchText += "&lastName=" + searchData.lastName;
    }
    if (searchData.sex) {
      searchText += "&sex=" + searchData.sex;
    }
    if (searchData.cardId) {
      searchText += "&cardId=" + searchData.cardId;
    }
    if (searchData.ractType) {
      searchText += "&ractType=" + searchData.ractType;
    }
    if (searchData.blushVersion) {
      searchText += "&blushVersion=" + searchData.blushVersion;
    }
    if (searchData.bibNumber) {
      searchText += "&bibNumber=" + searchData.bibNumber;
    }
    if (searchData.rowNumber) {
      searchText += "&rowNumber=" + searchData.rowNumber;
    }
    if (searchData.shirtType) {
      searchText += "&shirtType=" + searchData.shirtType;
    }
    if (searchData.shirtSize) {
      searchText += "&shirtSize=" + searchData.shirtSize;
    }
    if (searchData.pickUp) {
      searchText += "&pickUp=" + searchData.pickUp;
    }
    let token = window.sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let event = await axios.get(
      API_ENDPOINT + "event/" + id + "/search_data?" + searchText,
      config
    );
    return { status: true, data: event.data };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};
