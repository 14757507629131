import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import MuiLink from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import EventStore from "../../stores/eventStore";
import { getEventData, deleteEvent } from "../../api/event";
import { updateEvent } from "../../api/event";
import moment from "moment";

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarExport
        printOptions={{ disableToolbarButton: true }}
        csvOptions={{
          fileName: "export",
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  );
};

const TbReportEvent = () => {
  const { events, setEvents } = EventStore();
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  ///////
  const columns = [
    { field: "eventName", headerName: "กิจกรรม", width: 300 },
    { field: "organizer", headerName: "หน่วยงาน", width: 300 },
    {
      field: "eventLocation",
      headerName: "สถานที่จัดงาน",
      width: 150,
    },
    {
      field: "eventDate",
      headerName: "วันที่",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => moment(params.row.eventDate).format("DD/MM/YYYY"),
    },
    {
      field: "members",
      headerName: "ผู้เข้าร่วมกิจกรรม",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "pickUpY",
      headerName: "รับอุปกรณ์",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "pickUpN",
      headerName: "ยังไม้รับอุปกรณ์",
      width: 120,
      headerAlign: "center",
      align: "center",
    },
    /*
    {
      field: "action",
      width: 150,
      headerName: "ดู",
      sortable: false,
      filterable: false,
      headerAlign: "center",
      align: "center",
      disableExport: true,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1}>
            <Tooltip title="ผู้เข้าร่วมกิจกรรม">
              <MuiLink
                component={Link}
                to={`/report/event/${params.row.eventId}`}
              >
                <SearchIcon fontSize="small" />
              </MuiLink>
            </Tooltip>
          </Stack>
        );
      },
    },
    */
  ];
  //////

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        getRowId={(row) => row.eventId}
        autoHeight
        rows={events}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
};

export default TbReportEvent;
