import axios from "axios";
import { API_ENDPOINT } from "../constants/endpoint";

//CURD

export const updateSetting = async (id, data) => {
  try {
    let token = window.sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let event = await axios.patch(API_ENDPOINT + "setting/" + id, data, config);
    return { status: true, data: event.data };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};

export const getSetting = async (id) => {
  try {
    let token = window.sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let event = await axios.get(API_ENDPOINT + "setting/" + id, config);
    return { status: true, data: event.data };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};
