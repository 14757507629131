import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import AppLayout from "../components/AppLayout";
import AppBreacrumb from "../components/AppBreadcrumb";
import TbEvent from "../components/TbEvent";
import { getEventData, deleteEvent } from "../api/event";
import EventStore from "../stores/eventStore";
import SettingList from "../components/SettingList";

const SettingPage = () => {
  const { setEvents } = EventStore();
  const navigate = useNavigate();
  const loadData = async () => {
    getEventData(setEvents);
  };
  useEffect(() => {
    loadData();
  }, []);
  return (
    <AppLayout>
      <AppBreacrumb data={[{ label: "ตั้งค่า" }]} />
      <div className="app-card">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <div className="app-card-title">ตั้งค่า</div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div style={{ textAlign: "right" }}></div>
          </Grid>
        </Grid>
        <div>
          <SettingList />
        </div>
      </div>
    </AppLayout>
  );
};

export default SettingPage;
