import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import UploadIcon from "@mui/icons-material/Upload";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Divider from "@mui/material/Divider";
import AppLayout from "./../components/AppLayout";
import AppBreacrumb from "./../components/AppBreadcrumb";
import {
  getEventId,
  getEventMemberData,
  getEventMemberId,
} from "./../api/event";
import EventStore from "../stores/eventStore";
import TbEventMember from "../components/TbEventMember";
import ModalUpload from "../components/ModalUpload";
import FormSearch from "../components/FormSearch";

const EventMemberPage = () => {
  const navigate = useNavigate();
  const { setMembers } = EventStore();
  const [eventData, setEventData] = useState(null);
  const [openUpload, setOpenUpload] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const { eventId } = useParams();
  const loadEventData = async () => {
    let getEvent = await getEventId(eventId);
    if (getEvent.status === true) {
      setEventData(getEvent.data.data);
    }
  };
  const loadEventMemberData = async () => {
    getEventMemberData(setMembers, eventId);
  };

  useEffect(() => {
    loadEventData();
    loadEventMemberData();
  }, []);

  return (
    <AppLayout>
      {eventData !== null && (
        <AppBreacrumb
          data={[
            { label: "กิจกรรม", link: "/event" },
            {
              label: eventData.eventName,
              link: "/event/" + eventId + "/member",
            },
            { label: "ผู้เข้าร่วมกิจกรรม" },
          ]}
        />
      )}
      <div className="app-card">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <div className="app-card-title">ผู้เข้าร่วมกิจกรรม</div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div style={{ textAlign: "right" }}>
              <Stack
                spacing={1}
                direction="row"
                justifyContent="flex-end"
                alignItems="right"
              >
                <Button
                  variant="outlined"
                  startIcon={<SearchIcon />}
                  onClick={(e) => {
                    setOpenSearch(!openSearch);
                  }}
                >
                  ค้นหา
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<UploadIcon />}
                  onClick={(e) => {
                    setOpenUpload(true);
                  }}
                >
                  นำเข้าข้อมูล
                </Button>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={(e) => {
                    navigate(`/event/${eventId}/member/create`);
                  }}
                >
                  เพิ่มข้อมูล
                </Button>
              </Stack>
            </div>
          </Grid>
        </Grid>
        {openSearch === true && (
          <div style={{ marginBottom: 25 }}>
            <Divider textAlign="center">ค้นหา</Divider>
            <FormSearch eventId={eventId} />
            <br />
            <Divider />
          </div>
        )}
        {eventData !== null && (
          <TbEventMember eventId={eventId} eventName={eventData.eventName} />
        )}
        <ModalUpload
          open={openUpload}
          setOpen={setOpenUpload}
          loadData={loadEventMemberData}
          eventId={eventId}
        />
      </div>
    </AppLayout>
  );
};

export default EventMemberPage;
