import axios from "axios";
import { API_ENDPOINT } from "../constants/endpoint";

export const getReportEvent = async () => {
  try {
    let token = window.sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let event = await axios.get(API_ENDPOINT + "report/event", config);
    return { status: true, data: event.data };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};

export const getReportEventId = async (id) => {
  try {
    let token = window.sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let event = await axios.get(API_ENDPOINT + "report/event/" + id, config);
    return { status: true, data: event.data };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};

//FUNCTION

export const getReportEventData = async (store) => {
  let data = await getReportEvent();
  if (data.status === true) {
    store(data.data.data);
  }
};
