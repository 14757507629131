import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import PrintIcon from "@mui/icons-material/Print";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import EditIcon from "@mui/icons-material/Edit";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Routes, Route, useNavigate } from "react-router-dom";
import { updateSetting, getSetting } from "./../../api/setting";

const SettingList = () => {
  const navigate = useNavigate();
  const [printSize, setPrintSize] = useState(0);

  const loadSetting = async () => {
    let getSettingData = await getSetting("1");
    //alert(JSON.stringify(getSettingData));
    setPrintSize(getSettingData.data.data.settingValue);
  };

  const printHandleChange = async (event) => {
    //alert(event.target.value);
    updateSetting(1, { settingValue: event.target.value });
    setPrintSize(event.target.value);
  };

  useEffect(() => {
    loadSetting();
  }, []);

  return (
    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
      <ListItem
        alignItems="flex-start"
        secondaryAction={
          <FormControl>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              style={{ width: 180 }}
              defaultValue={printSize}
              value={printSize}
              onChange={printHandleChange}
            >
              <MenuItem value={80}>กว้างขนาด 80 MM</MenuItem>
              <MenuItem value={58}>กว้างขนาด 58 MM</MenuItem>
            </Select>
          </FormControl>
        }
      >
        <ListItemAvatar>
          <PrintIcon style={{ fontSize: 40 }} />
        </ListItemAvatar>
        <ListItemText
          primary="ตั้งค่ากระดาษ"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {"ตั้งค่ากระดาษสำหรับการปริ้น"}
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem
        alignItems="flex-start"
        secondaryAction={
          <EditIcon
            style={{ fontSize: 30 }}
            onClick={() => {
              navigate("/ract-type");
            }}
          />
        }
      >
        <ListItemAvatar>
          <DirectionsRunIcon style={{ fontSize: 40 }} />
        </ListItemAvatar>
        <ListItemText
          primary="ตั้งค่าประเภทการแข่งขัน"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {"ตั้งค่าประเภทการแข่งขัน"}
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </List>
  );
};

export default SettingList;
