import create from "zustand";

const AppStore = create((set) => ({
  isLoading: false,
  setIsLoading: (status) => set((state) => ({ isLoading: status })),
  alertShow: false,
  alertMsg: "",
  alertType: "success",
  printer: null,
  setAlertShow: (status) => set((state) => ({ alertShow: status })),
  setAlertMsg: (text) => set((state) => ({ alertMsg: text })),
  setAlertType: (type) => set((state) => ({ alertType: type })),
  setPrinter: (printer) => set((state) => ({ printer: printer })),
}));

export default AppStore;
