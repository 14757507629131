import * as React from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import { Link, useNavigate } from "react-router-dom";
import MuiLink from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import LoginIcon from "@mui/icons-material/Login";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FormSignIn from "../components/FormSignIn";
import Copyright from "../components/Copyright";

const theme = createTheme();

const EventCreateSuccessPage = () => {
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <DirectionsRunIcon sx={{ fontSize: 80 }} />
          <Typography component="h1" variant="h5">
            กิจกรรม
          </Typography>
          <Box sx={{ mt: 4, mb: 2 }}>
            <CheckCircleIcon sx={{ fontSize: 180, color: "#0F7217" }} />
          </Box>
          <Typography component="h1" variant="h5">
            บันทึกกิจกรรมเรียบร้อย
          </Typography>
          <Box sx={{ mt: 4, mb: 2 }}>
            <MuiLink
              underline="hover"
              color="inherit"
              component={Link}
              to={"/event"}
            >
              กิจกรรม
            </MuiLink>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
};

export default EventCreateSuccessPage;
