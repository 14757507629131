import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Link, useNavigate } from "react-router-dom";
import MuiLink from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FormSignIn from "../components/FormSignIn";
import Copyright from "../components/Copyright";
import { authUser } from "../api/auth";
import AuthStore from "../stores/authStore";
import { themeApp } from "../constants/theme";

const SignOutPage = () => {
  const navigate = useNavigate();
  const { isAuth, setIsAuth } = AuthStore();
  const onLogout = async () => {
    window.sessionStorage.removeItem("token");
    window.sessionStorage.removeItem("userType");
    //setIsAuth(false);
    //navigate("/sign-in");
    window.location = "/sign-in";
  };

  useEffect(() => {
    onLogout();
  }, []);

  return (
    <ThemeProvider theme={themeApp}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <DirectionsRunIcon sx={{ fontSize: 80 }} />
          <Typography component="h1" variant="h5">
            ออกจากระบบ
          </Typography>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
};

export default SignOutPage;
