import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Link, useNavigate } from "react-router-dom";
import MuiLink from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FormSignIn from "../components/FormSignIn";
import Copyright from "./../components/Copyright";
import { authUser } from "./../api/auth";
import AuthStore from "./../stores/authStore";
import { themeApp } from "./../constants/theme";

const SignInPage = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { isAuth, setIsAuth } = AuthStore();
  const onSubmit = async (data) => {
    let login = await authUser(data);
    if (login.status === true) {
      window.sessionStorage.setItem("token", login.data.token);
      window.sessionStorage.setItem("userType", login.data.data.userType);
      setIsAuth(true);
      navigate("/event");
    } else {
      setOpen(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <ThemeProvider theme={themeApp}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <DirectionsRunIcon sx={{ fontSize: 80 }} />
          <Typography component="h1" variant="h5">
            เข้าสู่ระบบ
          </Typography>
          <FormSignIn onSubmit={onSubmit} />
        </Box>
        {/*<Grid container>
          <Grid item xs>
            <MuiLink component={Link} to="/forgot-password" variant="body2">
              ลืมรหัสผ่าน?
            </MuiLink>
          </Grid>
          <Grid item>
            <MuiLink component={Link} to="/sign-up" variant="body2">
              ลงทะเบียน
            </MuiLink>
          </Grid>
        </Grid>*/}
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleClose}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง
          </Alert>
        </Snackbar>
      </Stack>
    </ThemeProvider>
  );
};

export default SignInPage;
