import React, { useState } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MuiLink from "@mui/material/Link";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Confirm from "../Confirm";
import { Link } from "react-router-dom";
import { updateUser, getUser } from "./../../api/user";
import UserStore from "./../../stores/userStore";

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarExport
        printOptions={{ disableToolbarButton: true }}
        csvOptions={{
          fileName: "export",
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  );
};

const TbUser = ({ data }) => {
  const { users, setUsers } = UserStore();
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const columns = [
    {
      field: "fullName",
      headerName: "ชื่อ-นามสกุล",
      width: 300,
      headerAlign: "center",
      valueGetter: (params) => params.row.firstName + " " + params.row.lastName,
    },
    { field: "email", headerName: "อีเมล", width: 300, headerAlign: "center" },
    {
      field: "userType",
      headerName: "ประเภทผู้ใช้งาน",
      width: 120,
      headerAlign: "center",
    },
    {
      field: "username",
      headerName: "Username",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      filterable: false,
      headerName: "เปิด/ปิด",
      sortable: false,
      headerAlign: "center",
      align: "center",
      disableExport: true,
      renderCell: (params) => {
        let chk = false;
        if (params.row.open === "Y") {
          chk = true;
        }
        return (
          <Switch
            defaultChecked={chk}
            onChange={(v) => {
              onOff(params.row.userId, v.target.checked);
            }}
          />
        );
      },
    },
    {
      field: "action",
      width: 150,
      headerName: "แก้ไข/ลบ",
      sortable: false,
      filterable: false,
      headerAlign: "center",
      align: "center",
      disableExport: true,
      renderCell: (params) => {
        const del = async (id) => {
          //let d = await deleteEvent(id);
          //getEventData(setEvents);
          setDeleteId(id);
          setOpen(true);
        };
        return (
          <Stack direction="row" spacing={1}>
            <Tooltip title={"แก้ไข"}>
              <MuiLink component={Link} to={`/user/${params.row.userId}/edit`}>
                <BorderColorIcon fontSize="small" />
              </MuiLink>
            </Tooltip>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Tooltip title="ลบ">
              <MuiLink
                component={Link}
                to={"#"}
                onClick={() => del(params.row.userId)}
              >
                <DeleteIcon fontSize="small" />
              </MuiLink>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  const confirmDelete = async (id) => {
    await updateUser(id, { status: "inactive" });
    let getUsers = await getUser();
    setUsers(getUsers.data.data);
    setOpen(false);
  };

  const onOff = async (id, status) => {
    let on = "N";
    if (status === true) {
      on = "Y";
    }
    await updateUser(id, { open: on });
  };

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        autoHeight
        rows={data}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
        }}
        getRowId={(row) => row.userId}
      />
      <Confirm
        title={"ลบข้อมูล"}
        desc="ยืนยันการลบข้อมูล"
        open={open}
        setOpen={setOpen}
        id={deleteId}
        confirmDelete={confirmDelete}
      />
    </div>
  );
};

export default TbUser;
