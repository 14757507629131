import React, { useEffect, useState, useRef } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import AppLayout from "../components/AppLayout";
import AppBreacrumb from "../components/AppBreadcrumb";
import { getEventData, deleteEvent } from "../api/event";
import EventStore from "../stores/eventStore";
import { ComponentToPrint } from "./../components/Print/PrintComponent";

const pageStyle = `
    @page {
        size: 57mm;
    }
`;

const PrintTestPage = () => {
  const { setEvents } = EventStore();
  const navigate = useNavigate();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: pageStyle,
  });

  const loadData = async () => {
    getEventData(setEvents);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <AppLayout>
      <AppBreacrumb data={[{ label: "ตั้งค่า" }]} />
      <div className="app-card">
        <ComponentToPrint ref={componentRef} />
        <button onClick={handlePrint}>Print this out!</button>
      </div>
      <div></div>
    </AppLayout>
  );
};

export default PrintTestPage;
