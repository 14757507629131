import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import AppLayout from "../components/AppLayout";
import AppBreacrumb from "../components/AppBreadcrumb";
import TbUser from "../components/TbUser";
import { getUser } from "../api/user";
import UserStore from "../stores/userStore";

const UserPage = () => {
  const { users, setUsers } = UserStore();
  const navigate = useNavigate();
  const handleClick = (event) => {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  };
  const loadData = async () => {
    let users = await getUser();
    setUsers(users.data.data);
  };
  useEffect(() => {
    loadData();
  }, []);
  return (
    <AppLayout>
      <AppBreacrumb data={[{ label: "ผู้ใช้งาน", link: "" }]} />
      <div className="app-card">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <div className="app-card-title">ผู้ใช้งาน</div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div style={{ textAlign: "right" }}>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={(e) => {
                  navigate("/user/create");
                }}
              >
                เพิ่มผู้ใช้งาน
              </Button>
            </div>
          </Grid>
        </Grid>
        <TbUser data={users} />
      </div>
    </AppLayout>
  );
};

export default UserPage;
