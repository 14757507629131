import axios from "axios";
import { API_ENDPOINT } from "./../constants/endpoint";

export const createUser = async (data) => {
  try {
    let user = await axios.post(API_ENDPOINT + "user", data);
    return { status: true, data: user.data };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};

export const addUser = async (data) => {
  try {
    let token = window.sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let user = await axios.post(API_ENDPOINT + "user/create", data, config);
    return { status: true, data: user.data };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};

export const getUser = async () => {
  try {
    let token = window.sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let user = await axios.get(API_ENDPOINT + "user", config);
    return { status: true, data: user.data };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};

export const updateUser = async (id, data) => {
  try {
    let token = window.sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let user = await axios.patch(API_ENDPOINT + "user/" + id, data, config);
    return { status: true, data: user.data };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};

export const getUserId = async (id) => {
  try {
    let token = window.sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let user = await axios.get(API_ENDPOINT + "user/" + id, config);
    return { status: true, data: user.data };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};

export const editUser = async (id, data) => {
  try {
    let token = window.sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let user = await axios.patch(
      API_ENDPOINT + "user/update/" + id,
      data,
      config
    );
    return { status: true, data: user.data };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};

export const savePass = async (data) => {
  try {
    let token = window.sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let user = await axios.patch(API_ENDPOINT + "user/save-pass", data, config);
    return { status: true, data: user.data };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};
