import React, { useRef } from "react";
import QRCode from "react-qr-code";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import moment from "moment";

export const PrintReceipt = React.forwardRef((props, ref) => {
  console.log("d", props);
  let sex = "";
  let status = "";
  if (props.printData !== undefined) {
    if (props.printData.sex === "M" || props.printData.sex === "m") {
      sex = "Male";
    } else if (props.printData.sex === "F" || props.printData.sex === "f") {
      sex = "Female";
    }

    if (props.printData.pickUp === "Y" || props.printData.pickUp === "y") {
      status = "STATUS : Received";
    }
  }
  let eName = null;
  if (
    props.printData !== undefined &&
    props.printData.eventData !== undefined
  ) {
    eName = props.printData.eventData.eventName;
  } else if (
    props.printData !== undefined &&
    props.printData.eventName !== undefined
  ) {
    eName = props.printData.eventName;
  }
  return (
    <div ref={ref} style={{ width: `${props.size}mm` }}>
      {props.printData !== undefined && (
        <div style={{ textAlign: "center" }}>
          <font style={{ fontSize: 16 }}>
            {/*props.printData.eventData.eventName*/}
            {eName}
          </font>
          <br />
          <font style={{ fontSize: 14 }}>
            {props.printData.firstName} {props.printData.lastName}
          </font>
          <br />
          <QRCode value={base64_encode(props.printData.memberId)} size={100} />
          <br />
          <font style={{ fontSize: 16 }}>{props.printData.blushVersion}</font>
          <br />
          <font style={{ fontSize: 16 }}>BIB {props.printData.bibNumber}</font>
          <br />
          <font style={{ fontSize: 16 }}>--------------------</font>
          <br />
          <font style={{ fontSize: 16 }}>
            {props.printData.ractTypeData.ractTypeNameEn}
          </font>
          <br />
          <font style={{ fontSize: 16 }}>--------------------</font>
          <br />
          <font style={{ fontSize: 16 }}>{props.printData.shirtSize}</font>
          <br />
          <font style={{ fontSize: 16 }}>{props.printData.shirtType}</font>
          <br />
          <font style={{ fontSize: 16 }}>--------------------</font>
          <br />
          <font style={{ fontSize: 12 }}>
            SEX : {sex} ROW : {props.printData.rowNumber} {status}
          </font>
          <br />
          <font style={{ fontSize: 12 }}>
            {moment().format("HH:mm DD/MM/YYYY")}
          </font>
        </div>
      )}
    </div>
  );
});
