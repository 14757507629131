import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import AppLayout from "./../components/AppLayout";
import AppBreacrumb from "./../components/AppBreadcrumb";
import TbEvent from "./../components/TbEvent";
import { getEventData, deleteEvent } from "./../api/event";
import EventStore from "./../stores/eventStore";

const EventPage = () => {
  const { setEvents } = EventStore();
  const navigate = useNavigate();
  const loadData = async () => {
    getEventData(setEvents);
  };
  useEffect(() => {
    loadData();
  }, []);
  return (
    <AppLayout>
      <AppBreacrumb data={[{ label: "กิจกรรม" }]} />
      <div className="app-card">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <div className="app-card-title">กิจกรรม</div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div style={{ textAlign: "right" }}>
              {window.sessionStorage.getItem("userType") === "admin" && (
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={(e) => {
                    navigate("/event/create");
                  }}
                >
                  เพิ่มกิจกรรม
                </Button>
              )}
            </div>
          </Grid>
        </Grid>

        <TbEvent />
      </div>
    </AppLayout>
  );
};

export default EventPage;
