import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import moment from "moment";
import AppLayout from "../components/AppLayout";
import AppBreacrumb from "../components/AppBreadcrumb";
import FormRactType from "../components/FormRactType";
import { createRactType } from "../api/master";
import AppStore from "../stores/appStore";

const RactTypeCreatePage = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { alertShow, setAlertShow, setAlertType, setAlertMsg } = AppStore();
  const onSubmit = async (data) => {
    let create = await createRactType(data);
    if (create.status === true) {
      setAlertType("success");
      setAlertMsg("บันทึกประเภทการแข่งขันเรียบร้อย");
      setAlertShow(true);
      navigate("/ract-type");
    } else {
      setAlertType("error");
      setAlertMsg("error");
      setAlertShow(true);
    }
    //alert(JSON.stringify(create));
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <AppLayout>
      <AppBreacrumb
        data={[
          { label: "ตั้งค่า", link: "/settingt" },
          { label: "ประเภทการแข่งขัน", link: "/ract-type" },
          { label: "เพิ่มประเภทการแข่งขัน" },
        ]}
      />
      <div className="app-card">
        <div className="app-card-title">ประเภทการแข่งขัน</div>
        <FormRactType onSubmit={onSubmit} data={null} />
      </div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          บันทึกข้อมูลเรียบร้อย
        </Alert>
      </Snackbar>
    </AppLayout>
  );
};

export default RactTypeCreatePage;
