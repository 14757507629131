import React from "react";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import HomePage from "../pages/HomePage";
import SignInPage from "../pages/SignInPage";
import SignUpPage from "../pages/SignUpPage";

const PublicRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<SignInPage />} />
      <Route path="/sign-in" element={<SignInPage />} />
      <Route path="/sign-up" element={<SignUpPage />} />
      <Route path="/*" element={<SignInPage />} />
    </Routes>
  );
};

export default PublicRouter;
