import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import moment from "moment";
import AppLayout from "../components/AppLayout";
import AppBreacrumb from "../components/AppBreadcrumb";
import FormEvent from "../components/FormEvent";
import { createEvent } from "./../api/event";
import AppStore from "./../stores/appStore";

const EventCreatePage = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { alertShow, setAlertShow, setAlertType, setAlertMsg } = AppStore();
  const onSubmit = async (data) => {
    data.eventOpen = "N";
    data.eventDate = moment(data.eventDate).format("YYYY-MM-DD");
    let create = await createEvent(data);
    if (create.status === true) {
      setAlertType("success");
      setAlertMsg("บันทึกกิจกรรมเรียบร้อย");
      setAlertShow(true);
      navigate("/event");
    } else {
      setAlertType("error");
      setAlertMsg("error");
      setAlertShow(true);
    }
    //alert(JSON.stringify(create));
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <AppLayout>
      <AppBreacrumb
        data={[{ label: "กิจกรรม", link: "/event" }, { label: "เพิ่มกิจกรรม" }]}
      />
      <div className="app-card">
        <div className="app-card-title">กิจกรรม</div>
        <FormEvent onSubmit={onSubmit} data={null} />
      </div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          บันทึกข้อมูลเรียบร้อย
        </Alert>
      </Snackbar>
    </AppLayout>
  );
};

export default EventCreatePage;
