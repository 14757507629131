import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import * as yup from "yup";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Tooltip from "@mui/material/Tooltip";
import { getEventData, deleteEvent, getEvent } from "./../../api/event";
import SelectEvent from "./../SelectEvent";

const schema = yup
  .object({
    firstName: yup.string().required("กรุณากรอกข้อมูล"),
    lastName: yup.string().required("กรุณากรอกข้อมูล"),
    username: yup.string().required("กรุณากรอกข้อมูล"),
    email: yup.string().email("กรุณาใส่อีเมล"),
    password: yup
      .string()
      .required("กรุณากรอกข้อมูล")
      .min(6, "รหัสผ่านอย่างน้อย 6 ตัวอักษร"),
    confirmPassword: yup
      .string()
      .required("กรุณากรอกข้อมูล")
      .oneOf([yup.ref("password"), null], "ยืนยันรหัสผ่านไม่ถูกต้อง"),
    userType: yup.string().required("กรุณากรอกข้อมูล"),
    //ractType: yup.string().required("กรุณากรอกข้อมูล"),
  })
  .required();

const FormUser = ({ onSubmit, data }) => {
  const [event, setEvent] = useState([]);
  const [editPass, setEditPass] = useState(false);
  const [selectEvent, setSelectEvent] = useState([]);
  const [myEvent, setMyEvent] = useState([]);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      username: "",
      password: "",
      confirmPassword: "",
      email: "",
      userType: "",
    },
    resolver: yupResolver(schema),
  });

  /////
  const loadData = async () => {
    let eventData = await getEvent();
    //alert(JSON.stringify(eventData.data.data));
    setEvent(eventData.data.data);
  };

  ////
  const onSubmitData = (v) => {
    v.event = selectEvent;
    onSubmit(v);
  };

  const onEditPass = () => {
    let status = !editPass;
    if (status === false) {
      setValue("password", "");
      setValue("confirmPassword", "");
    } else {
      setValue("password", "**********");
      setValue("confirmPassword", "**********");
    }
    setEditPass(status);
  };

  useEffect(() => {
    if (data !== null) {
      setValue("firstName", data.firstName);
      setValue("lastName", data.lastName);
      setValue("email", data.email);
      setValue("username", data.username);
      setValue("userType", data.userType);
      setValue("password", "**********");
      setValue("confirmPassword", "**********");
      setEditPass(true);
      setMyEvent(data.userEvent);
    }
  }, [data]);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <form onSubmit={handleSubmit(onSubmitData)} autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  label="ชื่อ"
                  error={errors.firstName ? true : false}
                  helperText={errors.firstName?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  label="นามสกุล"
                  error={errors.lastName ? true : false}
                  helperText={errors.lastName?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  label="อีเมล"
                  error={errors.email ? true : false}
                  helperText={errors.email?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="username"
              control={control}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  label="ชื่อผู้ใช้งาน"
                  error={errors.lastName ? true : false}
                  helperText={errors.lastName?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  type="password"
                  margin="normal"
                  fullWidth
                  label="รหัสผ่าน"
                  error={errors.password ? true : false}
                  helperText={errors.password?.message}
                  disabled={editPass}
                  InputProps={{
                    endAdornment: data !== null && (
                      <InputAdornment position="end">
                        <Tooltip title="แก้ไขรหัสผ่าน">
                          <IconButton
                            onClick={onEditPass}
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            <BorderColorIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field }) => (
                <TextField
                  type="password"
                  margin="normal"
                  fullWidth
                  label="ยืนยันรหัสผ่าน"
                  error={errors.confirmPassword ? true : false}
                  helperText={errors.confirmPassword?.message}
                  disabled={editPass}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="userType"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <FormControl sx={{ minWidth: 120 }} margin="normal" fullWidth>
                  <InputLabel>ประเภทผู้ใช้งาน</InputLabel>
                  <Select
                    value={value}
                    label="ประเภทผู้ใช้งาน"
                    onChange={onChange}
                    error={errors.userType ? true : false}
                  >
                    <MenuItem value={"admin"}>Admin</MenuItem>
                    <MenuItem value={"organizer"}>Organizer</MenuItem>
                    <MenuItem value={"staff"}>Staff</MenuItem>
                  </Select>
                  <FormHelperText error>
                    {errors.userType?.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectEvent
              data={event}
              setSelectEvent={setSelectEvent}
              myEvent={myEvent}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button type="submit" variant="contained">
              บันทึก
            </Button>
          </Grid>
        </Grid>
      </form>
    </LocalizationProvider>
  );
};

export default FormUser;
