import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import MuiLink from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";

const AppBreacrumb = ({ data }) => {
  const handleClick = () => {};

  return (
    <div onClick={handleClick} style={{ marginBottom: 20 }}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <MuiLink underline="hover" color="inherit" href="/">
          หน้าแรก
        </MuiLink>
        {data.map((item, index) => {
          if (item.link !== undefined) {
            return (
              <MuiLink
                key={index}
                underline="hover"
                color="inherit"
                component={Link}
                to={item.link}
              >
                {item.label}
              </MuiLink>
            );
          } else {
            return (
              <Typography key={index} color="text.primary">
                {item.label}
              </Typography>
            );
          }
        })}
      </Breadcrumbs>
    </div>
  );
};

export default AppBreacrumb;
