import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText";
import * as yup from "yup";
import {
  getShirtType,
  getShirtSize,
  getRactType,
  getRow,
} from "./../../api/master";
import { WEB_ENDPOINT } from "./../../constants/endpoint";

const schema = yup
  .object({
    firstName: yup.string().required("กรุณากรอกข้อมูล"),
    //lastName: yup.string().required("กรุณากรอกข้อมูล"),
    sex: yup.string().required("กรุณากรอกข้อมูล"),
    cardId: yup.string().required("กรุณากรอกข้อมูล"),
    sex: yup.string().required("กรุณากรอกข้อมูล"),
    ractType: yup.string().required("กรุณากรอกข้อมูล"),
  })
  .required();

const FormMember = ({ onSubmit, data }) => {
  const [pDate, setPDate] = useState(null);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      sex: "",
      cardId: "",
      ractType: "",
      blushVersion: "",
      bibNumber: "",
      rowNumber: "",
      shirtType: "",
      shirtSize: "",
      pickUp: "N",
    },
    resolver: yupResolver(schema),
  });

  /////
  const [shirtType, setShirtType] = useState([]);
  const [shirtSize, setShirtSize] = useState([]);
  const [ractType, setRactType] = useState([]);
  const [row, setRow] = useState([]);

  const loadMasterData = async () => {
    let shirtTypeData = await getShirtType();
    let shirtArr = [];
    if (shirtTypeData.data.data.length > 0) {
      for (let i = 0; i < shirtTypeData.data.data.length; i++) {
        shirtArr.push(shirtTypeData.data.data[i]);
      }
    }
    setShirtType(shirtArr);
    ////////
    let shirtSizeData = await getShirtSize();
    let shirtSizeArr = [];
    if (shirtSizeData.data.data.length > 0) {
      for (let i = 0; i < shirtSizeData.data.data.length; i++) {
        shirtSizeArr.push(shirtSizeData.data.data[i]);
      }
    }
    setShirtSize(shirtSizeArr);
    ////////
    let ractTypeData = await getRactType();
    let ractTypeArr = [];
    if (ractTypeData.data.data.length > 0) {
      for (let i = 0; i < ractTypeData.data.data.length; i++) {
        ractTypeArr.push(ractTypeData.data.data[i]);
      }
    }
    setRactType(ractTypeArr);
    ////////
    let rowData = await getRow();
    let rowArr = [];
    if (rowData.data.data.length > 0) {
      for (let i = 0; i < rowData.data.data.length; i++) {
        rowArr.push(rowData.data.data[i]);
      }
    }
    setRow(rowArr);
  };

  const onSubmitData = (v) => {
    if (pDate !== null) {
      v.pickUpDate = pDate;
    }
    onSubmit(v);
  };

  useEffect(() => {
    if (data !== null) {
      //setValue("eventName", data.eventName);
      //setValue("eventDesc", data.eventDesc);
      //setValue("organizer", data.organizer);
      //setValue("eventLocation", data.eventLocation);
      //setValue("eventDate", data.eventDate);

      setValue("firstName", data.firstName);
      setValue("lastName", data.lastName);
      setValue("sex", data.sex);
      setValue("cardId", data.cardId);
      setValue("ractType", data.ractType);
      setValue("blushVersion", data.blushVersion);
      setValue("bibNumber", data.bibNumber);
      setValue("rowNumber", data.rowNumber);
      setValue("shirtType", data.shirtType);
      setValue("shirtSize", data.shirtSize);
      setValue("pickUp", data.pickUp !== null ? data.pickUp : "");
      if (data.pickUpDate !== undefined && data.pickUpDate !== null) {
        setPDate(data.pickUpDate);
      }
    }
  }, [data]);

  useEffect(() => {
    loadMasterData();
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <form onSubmit={handleSubmit(onSubmitData)} autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  label="ชื่อ"
                  error={errors.firstName ? true : false}
                  helperText={errors.firstName?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  label="นามสกุล"
                  error={errors.lastName ? true : false}
                  helperText={errors.lastName?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="sex"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <FormControl sx={{ minWidth: 120 }} margin="normal" fullWidth>
                  <InputLabel>เพศ</InputLabel>
                  <Select
                    value={value}
                    label="เพศ"
                    onChange={onChange}
                    error={errors.sex ? true : false}
                  >
                    <MenuItem value={"m"}>ชาย</MenuItem>
                    <MenuItem value={"f"}>หญิง</MenuItem>
                  </Select>
                  <FormHelperText error>{errors.sex?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="cardId"
              control={control}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  label="หมายเลขบัตรประชาชน"
                  error={errors.cardId ? true : false}
                  helperText={errors.cardId?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="bibNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  label="BIB"
                  error={errors.bibNumber ? true : false}
                  helperText={errors.bibNumber?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {/*
            <Controller
              name="rowNumber"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <FormControl sx={{ minWidth: 120 }} margin="normal" fullWidth>
                  <InputLabel>ROW</InputLabel>
                  <Select
                    value={value}
                    label="ROW"
                    onChange={onChange}
                    error={errors.ractType ? true : false}
                  >
                    {row.map((item, key) => (
                      <MenuItem key={key} value={item.rowDataId}>
                        {item.rowDataName}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>
                    {errors.ractType?.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
                    */}
            <Controller
              name="rowNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  label="ROW"
                  error={errors.rowNumber ? true : false}
                  helperText={errors.rowNumber?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="ractType"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <FormControl sx={{ minWidth: 120 }} margin="normal" fullWidth>
                  <InputLabel>ประเภทการแข่งขัน</InputLabel>
                  <Select
                    value={value}
                    label="ประเภทการแข่งขัน"
                    onChange={onChange}
                    error={errors.ractType ? true : false}
                  >
                    {ractType.map((item, key) => (
                      <MenuItem key={key} value={item.ractTypeId}>
                        {item.ractTypeName}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>
                    {errors.ractType?.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="blushVersion"
              control={control}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  label="รุ่น-อายุ"
                  error={errors.blushVersion ? true : false}
                  helperText={errors.blushVersion?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {/*
            <Controller
              name="shirtType"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <FormControl sx={{ minWidth: 120 }} margin="normal" fullWidth>
                  <InputLabel>ประเภทเสื้อ</InputLabel>
                  <Select
                    value={value}
                    label="ประเภทเสื้อ"
                    onChange={onChange}
                    error={errors.shirtType ? true : false}
                  >
                    {shirtType.map((item, key) => (
                      <MenuItem key={key} value={item.shirtTypeId}>
                        {item.shirtTypeName}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>
                    {errors.shirtType?.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
                    */}

            <Controller
              name="shirtType"
              control={control}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  label="ประเภทเสื้อ"
                  error={errors.shirtType ? true : false}
                  helperText={errors.shirtType?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {/*
            <Controller
              name="shirtSize"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <FormControl sx={{ minWidth: 120 }} margin="normal" fullWidth>
                  <InputLabel>ขนาดเสื้อ</InputLabel>
                  <Select
                    value={value}
                    label="ขนาดเสื้อ"
                    onChange={onChange}
                    error={errors.shirtSize ? true : false}
                  >
                    {shirtSize.map((item, key) => (
                      <MenuItem key={key} value={item.shirtSizeId}>
                        {item.shirtSizeName}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>
                    {errors.shirtSize?.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
                    */}
            <Controller
              name="shirtSize"
              control={control}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  label="ขนาดเสื้อ"
                  error={errors.shirtSize ? true : false}
                  helperText={errors.shirtSize?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="pickUp"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <FormControl sx={{ minWidth: 120 }} margin="normal" fullWidth>
                  <InputLabel>สถานะการรับเสื้อ</InputLabel>
                  <Select
                    value={value}
                    label="สถานะการรับเสื้อ"
                    onChange={onChange}
                  >
                    <MenuItem value={"N"}>ยังไม่ได้รับ</MenuItem>
                    <MenuItem value={"Y"}>รับแล้ว</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="pickUpDate"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <DateTimePicker
                  label="วันที่รับเสื้อ"
                  inputFormat="hh:mm DD/MM/YYYY"
                  onChange={(v) => setPDate(v)}
                  value={pDate}
                  renderInput={(params) => (
                    <TextField margin="normal" fullWidth {...params} />
                  )}
                />
              )}
            />
          </Grid>
          {/*-------------Image---------------*/}
          {data !== undefined && data !== null && (
            <>
              <Grid item xs={12} sm={4}>
                รูปถ่าย
                <div
                  style={{
                    minHeight: 300,
                    maxHeight: 300,
                    backgroundColor: "#E7E7E7",
                  }}
                >
                  {data.cameraFile !== null && (
                    <img
                      src={`${WEB_ENDPOINT}uploads/registers/${data.cameraFile}`}
                      style={{ width: "100%", maxHeight: 300 }}
                    />
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                รูปอัพโหลด
                <div
                  style={{
                    minHeight: 300,
                    maxHeight: 300,
                    backgroundColor: "#E7E7E7",
                  }}
                >
                  {data.photoFile !== null && (
                    <img
                      src={`${WEB_ENDPOINT}uploads/registers/${data.photoFile}`}
                      style={{ width: "100%", maxHeight: 300 }}
                    />
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                รูปลายเซ็น
                <div
                  style={{
                    minHeight: 300,
                    maxHeight: 300,
                    backgroundColor: "#E7E7E7",
                  }}
                >
                  {data.signatureFile !== null && (
                    <img
                      src={`${WEB_ENDPOINT}uploads/registers/${data.signatureFile}`}
                      style={{ width: "100%", maxHeight: 300 }}
                    />
                  )}
                </div>
              </Grid>
            </>
          )}
          {/*------------------------------*/}
          <Grid item xs={12} sm={12}>
            <Button type="submit" variant="contained">
              บันทึก
            </Button>
          </Grid>
        </Grid>
      </form>
    </LocalizationProvider>
  );
};

export default FormMember;
