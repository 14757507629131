import axios from "axios";
import { API_ENDPOINT } from "../constants/endpoint";

//CURD

export const getShirtType = async () => {
  try {
    let token = window.sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let event = await axios.get(API_ENDPOINT + "master/shirt-type", config);
    return { status: true, data: event.data };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};

export const getShirtSize = async () => {
  try {
    let token = window.sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let event = await axios.get(API_ENDPOINT + "master/shirt-size", config);
    return { status: true, data: event.data };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};

export const getRactType = async () => {
  try {
    let token = window.sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let event = await axios.get(API_ENDPOINT + "master/ract-type", config);
    return { status: true, data: event.data };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};

export const getRow = async () => {
  try {
    let token = window.sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let event = await axios.get(API_ENDPOINT + "master/row", config);
    return { status: true, data: event.data };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};

export const createRactType = async (data) => {
  try {
    let token = window.sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let event = await axios.post(
      API_ENDPOINT + "master/ract-type",
      data,
      config
    );
    return { status: true, data: event.data };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};

export const getRactTypeById = async (id) => {
  try {
    let token = window.sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let event = await axios.get(
      API_ENDPOINT + "master/ract-type/" + id,
      config
    );
    return { status: true, data: event.data };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};

export const updateRactType = async (id, data) => {
  try {
    let token = window.sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let event = await axios.patch(
      API_ENDPOINT + "master/ract-type/" + id,
      data,
      config
    );
    return { status: true, data: event.data };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};

export const deleteRactTypeById = async (id) => {
  try {
    let token = window.sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let event = await axios.delete(
      API_ENDPOINT + "master/ract-type/" + id,
      config
    );
    return { status: true, data: event.data };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};

//FUNCTION
