import React, { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import Select from "@mui/material/Select";
import * as yup from "yup";

const schema = yup
  .object({
    eventName: yup.string().required("กรุณากรอกข้อมูล"),
    eventDate: yup.string().required("กรุณากรอกข้อมูล"),
    eventDesc: yup.string().required("กรุณากรอกข้อมูล"),
    organizer: yup.string().required("กรุณากรอกข้อมูล"),
    eventLocation: yup.string().required("กรุณากรอกข้อมูล"),
  })
  .required();

const FormEvent = ({ onSubmit, data }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      eventName: "",
      eventDate: "",
      eventDesc: "",
      organizer: "",
      eventLocation: "",
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (data !== null) {
      setValue("eventName", data.eventName);
      setValue("eventDesc", data.eventDesc);
      setValue("organizer", data.organizer);
      setValue("eventLocation", data.eventLocation);
      setValue("eventDate", data.eventDate);
    }
  }, [data]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Controller
              name="eventName"
              control={control}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  label="ชื่อกิจกรรม"
                  error={errors.eventName ? true : false}
                  helperText={errors.eventName?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Controller
              name="eventDesc"
              control={control}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  label="รายละเอียด"
                  error={errors.eventDesc ? true : false}
                  helperText={errors.eventDesc?.message}
                  multiline
                  rows={4}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="organizer"
              control={control}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  label="หน่วยงาน"
                  error={errors.organizer ? true : false}
                  helperText={errors.organizer?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="eventDate"
              control={control}
              render={({ field }) => (
                <DesktopDatePicker
                  label="วันที่จัดกิจกรรม"
                  inputFormat="DD/MM/YYYY"
                  onChange={() => {}}
                  {...field}
                  renderInput={(params) => (
                    <TextField margin="normal" fullWidth {...params} />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Controller
              name="eventLocation"
              control={control}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  label="สถานที่จัดงาน"
                  error={errors.eventLocation ? true : false}
                  helperText={errors.eventLocation?.message}
                  multiline
                  rows={4}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <Button type="submit" variant="contained">
              บันทึก
            </Button>
          </Grid>
        </Grid>
      </form>
    </LocalizationProvider>
  );
};

export default FormEvent;
