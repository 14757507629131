import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import * as yup from "yup";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Tooltip from "@mui/material/Tooltip";
import { getEventData, deleteEvent, getEvent } from "../../api/event";

const schema = yup
  .object({
    oldPassword: yup.string().required("กรุณากรอกข้อมูล"),
    password: yup
      .string()
      .required("กรุณากรอกข้อมูล")
      .min(6, "รหัสผ่านอย่างน้อย 6 ตัวอักษร"),
    confirmPassword: yup
      .string()
      .required("กรุณากรอกข้อมูล")
      .oneOf([yup.ref("password"), null], "ยืนยันรหัสผ่านไม่ถูกต้อง"),
  })
  .required();

const FormPassword = ({ onSubmit, data }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      oldPassword: "",
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(schema),
  });

  ////
  const onSubmitData = (v) => {
    onSubmit(v);
  };

  useEffect(() => {}, []);

  return (
    <form onSubmit={handleSubmit(onSubmitData)} autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Controller
            name="oldPassword"
            control={control}
            render={({ field }) => (
              <TextField
                type="password"
                margin="normal"
                fullWidth
                label="รหัสผ่านเดิม"
                error={errors.oldPassword ? true : false}
                helperText={errors.oldPassword?.message}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                type="password"
                margin="normal"
                fullWidth
                label="รหัสผ่านใหม่"
                error={errors.password ? true : false}
                helperText={errors.password?.message}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field }) => (
              <TextField
                type="password"
                margin="normal"
                fullWidth
                label="ยืนยันรหัสผ่าน"
                error={errors.confirmPassword ? true : false}
                helperText={errors.confirmPassword?.message}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Button type="submit" variant="contained">
            บันทึก
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default FormPassword;
