import React, { useState, useEffect, useRef } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import PrintIcon from "@mui/icons-material/Print";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import MuiLink from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import {
  Text,
  Image,
  QRCode,
  Line,
  Printer,
  render,
  Br,
  Cut,
} from "react-thermal-printer";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import { useReactToPrint } from "react-to-print";
import { PrintReceipt } from "./../../components/Print/PrintReceipt";
import Confirm from "../Confirm";
import AppStore from "../../stores/appStore";
import EventStore from "./../../stores/eventStore";
import {
  getEventData,
  deleteEvent,
  getEventMemberId,
  getEventMember,
  updateEventMember,
  getEventMemberData,
} from "./../../api/event";
import {
  getShirtType,
  getShirtSize,
  getRactType,
  getRow,
} from "./../../api/master";
import { getSetting } from "./../../api/setting";
import moment from "moment";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarExport
        printOptions={{ disableToolbarButton: true }}
        csvOptions={{
          fileName: "export",
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  );
};

const TbEventMember = ({ eventId, eventName }) => {
  const { setPrinter, printer } = AppStore();
  const { setMembers } = EventStore();
  const { members } = EventStore();
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [shirtType, setShirtType] = useState([]);
  const [shirtSize, setShirtSize] = useState([]);
  const [ractType, setRactType] = useState([]);
  const [row, setRow] = useState([]);
  const [printWidth, setPrintWidth] = useState(58);
  const [printData, setPrintData] = useState();
  ///////
  const columns = [
    {
      field: "eventName",
      headerName: "ชื่อ-นามสกุล",
      headerAlign: "center",
      width: 300,
      valueGetter: (params) => params.row.firstName + " " + params.row.lastName,
    },
    {
      field: "sex",
      headerName: "เพศ",
      headerAlign: "center",
      align: "center",
      width: 100,
      type: "singleSelect",
      valueOptions: ["ชาย", "หญิง"],
      valueGetter: (params) => {
        let text = "";
        if (params.value === "m") {
          text = "ชาย";
        } else if (params.value === "f") {
          text = "หญิง";
        }
        return text;
      },
    },
    {
      field: "cardId",
      headerName: "บัตรประชาชน",
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "bibNumber",
      headerName: "BIB",
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "rowNumber",
      headerName: "ROW",
      headerAlign: "center",
      align: "center",
      width: 150,
      //type: "singleSelect",
      //valueOptions: row,
      //valueGetter: (params) => params.row.rowData.rowDataName,
    },
    {
      field: "ractType",
      headerName: "ประเภทการแข่งขัน",
      headerAlign: "center",
      align: "center",
      hide: true,
      width: 150,
      type: "singleSelect",
      valueOptions: ractType,
      valueGetter: (params) => params.row.ractTypeData.ractTypeName,
    },
    {
      field: "blushVersion",
      headerName: "รุ่น-อายุ",
      headerAlign: "center",
      align: "center",
      hide: true,
      width: 150,
    },
    {
      field: "shirtType",
      headerName: "ประเภทเสื้อ",
      headerAlign: "center",
      align: "center",
      hide: true,
      width: 150,
      //type: "singleSelect",
      //valueOptions: shirtType,
      //valueGetter: (params) => params.row.shirtTypeData.shirtTypeName,
    },
    {
      field: "shirtSize",
      headerName: "ขนาดเสื้อ",
      headerAlign: "center",
      align: "center",
      hide: true,
      width: 150,
      //type: "singleSelect",
      //valueOptions: shirtSize,
      //valueGetter: (params) => params.row.shirtSizeData.shirtSizeName,
    },
    {
      field: "pickUp",
      headerName: "รับเสื้อ",
      headerAlign: "center",
      align: "center",
      width: 150,
      type: "singleSelect",
      valueOptions: ["รับแล้ว", "ยังไม่ได้รับ"],
      valueGetter: (params) => {
        let text = "";
        if (params.value === "N") {
          text = "ยังไม่ได้รับ";
        } else if (params.value === "Y") {
          text = "รับแล้ว";
        }
        return text;
      },
      renderCell: (params) => {
        let text = "";
        if (params.row.pickUp === "N") {
          text = <span style={{ color: "red" }}>ยังไม่ได้รับ</span>;
        } else if (params.row.pickUp === "Y") {
          text = <span style={{ color: "green" }}>รับแล้ว</span>;
        }
        return text;
      },
    },
    {
      field: "pickUpDate",
      headerName: "เวลาที่รับ",
      headerAlign: "center",
      align: "center",
      width: 150,
      type: "dateTime",
      valueGetter: ({ value }) => value && new Date(value),
      renderCell: (params) => {
        let text = "";
        if (params.value !== null) {
          text = moment(params.value).format("HH:mm DD/MM/YYYY");
        }
        return text;
      },
    },
    {
      field: "action",
      width: 150,
      headerName: "แก้ไข/ลบ",
      sortable: false,
      filterable: false,
      headerAlign: "center",
      align: "center",
      disableExport: true,
      renderCell: (params) => {
        const del = async (id) => {
          //let d = await deleteEvent(id);
          //getEventData(setEvents);
          setDeleteId(id);
          setOpen(true);
        };

        return (
          <Stack direction="row" spacing={1}>
            <Tooltip title={"รับอุปกรณ์"}>
              <MuiLink
                onClick={() => {
                  saveReceipt(params.row.eventId, params.row.memberId);
                }}
              >
                <ArrowDownwardIcon fontSize="small" />
              </MuiLink>
            </Tooltip>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Tooltip title={"พิมพ์ใบเสร็จ"}>
              <MuiLink
                onClick={() => {
                  //printReceipt(params.row.eventId, params.row.memberId);
                  params.row.eventName = eventName;
                  setPrintData(params.row);
                  setTimeout(() => {
                    handlePrint();
                  }, 300);
                }}
              >
                <PrintIcon fontSize="small" />
              </MuiLink>
            </Tooltip>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Tooltip title={"แก้ไข"}>
              <MuiLink
                component={Link}
                to={`/event/${params.row.eventId}/member/${params.row.memberId}/edit`}
              >
                <BorderColorIcon fontSize="small" />
              </MuiLink>
            </Tooltip>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Tooltip title="ลบ">
              <MuiLink
                component={Link}
                to={"#"}
                onClick={() => del(params.row.memberId)}
              >
                <DeleteIcon fontSize="small" />
              </MuiLink>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];
  //////
  const confirmDelete = async (id) => {
    await updateEventMember(eventId, id, { status: "inactive" });
    getEventMemberData(setMembers, eventId);
    //getEventData(setEvents);
    setOpen(false);
  };

  const loadMasterData = async () => {
    let shirtTypeData = await getShirtType();
    let shirtArr = [];
    if (shirtTypeData.data.data.length > 0) {
      for (let i = 0; i < shirtTypeData.data.data.length; i++) {
        shirtArr.push(shirtTypeData.data.data[i].shirtTypeName);
      }
    }
    setShirtType(shirtArr);
    ////////
    let shirtSizeData = await getShirtSize();
    let shirtSizeArr = [];
    if (shirtSizeData.data.data.length > 0) {
      for (let i = 0; i < shirtSizeData.data.data.length; i++) {
        shirtSizeArr.push(shirtSizeData.data.data[i].shirtSizeName);
      }
    }
    setShirtSize(shirtSizeArr);
    ////////
    let ractTypeData = await getRactType();
    let ractTypeArr = [];
    if (ractTypeData.data.data.length > 0) {
      for (let i = 0; i < ractTypeData.data.data.length; i++) {
        ractTypeArr.push(ractTypeData.data.data[i].ractTypeName);
      }
    }
    setRactType(ractTypeArr);
    ////////
    let rowData = await getRow();
    let rowArr = [];
    if (rowData.data.data.length > 0) {
      for (let i = 0; i < rowData.data.data.length; i++) {
        rowArr.push(rowData.data.data[i].rowDataName);
      }
    }
    setRow(rowArr);
  };

  const getSettingData = async () => {
    let getSettingData = await getSetting("1");
    setPrintWidth(getSettingData.data.data.settingValue);
  };

  const printReceipt = async (eventId, memberId) => {
    let memberData = await getEventMemberId(eventId, memberId);
    alert(JSON.stringify(memberData.data.data));
    let m = memberData.data.data;
    //alert("Print");
    const data: Uint8Array = await render(
      <Printer type="epson" width={printWidth} debug={true}>
        <Text size={{ width: 1.8, height: 1.8 }} align="center">
          {m.eventData.eventName}
        </Text>
        <Br />
        <Text align="center">
          {m.firstName} {m.lastName}
        </Text>
        <Br />
        <QRCode align="center" content={base64_encode(m.memberId)} />
        <Br />
        <Text align="center">{m.blushVersion}</Text>
        <Text align="center">BIB {m.bibNumber}</Text>
        <Text align="center">--------------------</Text>
        <Text align="center">{m.ractTypeData.ractTypeNameEn}</Text>
        <Text align="center">--------------------</Text>
        <Text align="center">{m.shirtSize}</Text>
        <Text align="center">{m.shirtType}</Text>
        <Text align="center">--------------------</Text>
        <Text align="center">{moment().format("HH:mm DD/MM/YYYY")}</Text>
        <Cut lineFeeds={2} />
      </Printer>
    );

    try {
      //const port = await navigator.serial.requestPort();
      //await port.open({ baudRate: 9600 });
      let _port = printer;
      if (_port == null) {
        _port = await navigator.serial.requestPort();
        await _port.open({ baudRate: 9600 });
        setPrinter(_port);
      }

      const writer = _port.writable?.getWriter();
      if (writer != null) {
        await writer.write(data);
        writer.releaseLock();
      }
    } catch (err) {
      alert("Printer error กรุณาปริ้นใหม่");
    }
  };

  /*------PRINT------*/

  const [openM, setOpenM] = useState(false);
  const [dSelect, setDSelect] = useState(new Date());
  const [mSelect, setMSelect] = useState("");
  const [eSelect, setESelect] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const saveReceipt = async (eventId, memberId) => {
    setESelect(eventId);
    setMSelect(memberId);
    setOpenM(true);
  };

  const confirmSaveReceipt = async () => {
    await updateEventMember(eSelect, mSelect, {
      pickUpDate: dSelect,
      pickUp: "Y",
    });
    getEventMemberData(setMembers, eventId);
    setSnackbarOpen(true);
    setOpenM(false);
  };

  const handleClose = () => {
    setOpenM(false);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
        @page {
            size: ${printWidth}mm landscape;
            margin: 0mm;
            padding: 0mm;
        }
      `,
  });

  useEffect(() => {
    loadMasterData();
    getSettingData();
  }, []);

  //const componentRef = React.useRef();

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        getRowId={(row) => row.memberId}
        autoHeight
        rows={members}
        columns={columns}
        pageSize={30}
        rowsPerPageOptions={[30]}
        disableSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
        }}
      />
      <Confirm
        title={"ลบข้อมูล"}
        desc="ยืนยันการลบข้อมูล?"
        open={open}
        setOpen={setOpen}
        id={deleteId}
        confirmDelete={confirmDelete}
      />
      <div style={{ display: "none" }}>
        <PrintReceipt
          ref={componentRef}
          printData={printData}
          size={printWidth}
        />
      </div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Dialog
          open={openM}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth={"sm"}
        >
          <DialogTitle id="alert-dialog-title">รับอุปกรณ์</DialogTitle>
          <DialogContent>
            <DesktopDatePicker
              label="วันที่จัดกิจกรรม"
              inputFormat="DD/MM/YYYY"
              value={dSelect}
              onChange={(v) => {
                setDSelect(v);
              }}
              renderInput={(params) => (
                <TextField margin="normal" fullWidth {...params} />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={confirmSaveReceipt}>
              บันทึก
            </Button>
            <Button onClick={handleClose} variant="outlined" color="error">
              ยกเลิก
            </Button>
          </DialogActions>
        </Dialog>
      </LocalizationProvider>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          บันทึกข้อมูลเรียบร้อย
        </Alert>
      </Snackbar>
    </div>
  );
};

export default TbEventMember;
