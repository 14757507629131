import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";

const schema = yup
  .object({
    username: yup.string().required("กรุณากรอกข้อมูล"),
    password: yup.string().required("กรุณากรอกข้อมูล"),
  })
  .required();

const FormSignIn = ({ onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Box sx={{ mt: 1 }}>
        <Controller
          name="username"
          control={control}
          render={({ field }) => (
            <TextField
              margin="normal"
              fullWidth
              label="ชื่อผู้ใช้งาน"
              error={errors.username ? true : false}
              helperText={errors.username?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              margin="normal"
              fullWidth
              label="รหัสผ่าน"
              type="password"
              error={errors.password ? true : false}
              helperText={errors.password?.message}
              {...field}
            />
          )}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          เข้าสู่ระบบ
        </Button>
      </Box>
    </form>
  );
};

export default FormSignIn;
