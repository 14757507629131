import React, { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";

const schema = yup
  .object({
    ractTypeName: yup.string().required("กรุณากรอกข้อมูล"),
    ractTypeNameEn: yup.string().required("กรุณากรอกข้อมูล"),
  })
  .required();

const FormRactType = ({ onSubmit, data }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      ractTypeName: "",
      ractTypeNameEn: "",
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (data !== null) {
      setValue("ractTypeName", data.ractTypeName);
      setValue("ractTypeNameEn", data.ractTypeNameEn);
    }
  }, [data]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Controller
              name="ractTypeName"
              control={control}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  label="ชื่อประเภทการแข่งขัน(TH)"
                  error={errors.ractTypeName ? true : false}
                  helperText={errors.ractTypeName?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Controller
              name="ractTypeNameEn"
              control={control}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  label="ชื่อประเภทการแข่งขัน(EN)"
                  error={errors.ractTypeNameEn ? true : false}
                  helperText={errors.ractTypeNameEn?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <Button type="submit" variant="contained">
              บันทึก
            </Button>
          </Grid>
        </Grid>
      </form>
    </LocalizationProvider>
  );
};

export default FormRactType;
