import React, { useEffect, useState } from "react";
import { Routes, Route, Link } from "react-router-dom";
import PublicRouter from "./public";
import PrivateRouter from "./private";
import AuthStore from "./../stores/authStore";

const AppRouter = () => {
  const [state, setState] = useState({
    load: true,
  });
  const { isAuth, setIsAuth } = AuthStore();
  useEffect(() => {
    const onAuth = async () => {
      let sessionToken = window.sessionStorage.getItem("token");
      if (sessionToken !== undefined && sessionToken !== null) {
        setIsAuth(true);
      }
      setState({ load: false });
    };
    onAuth();
  }, []);
  return state.load === false ? (
    <div>{isAuth === true ? <PrivateRouter /> : <PublicRouter />}</div>
  ) : (
    <div>loading</div>
  );
};

export default AppRouter;
