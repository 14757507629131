import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import moment from "moment";
import AppLayout from "../components/AppLayout";
import AppBreacrumb from "../components/AppBreadcrumb";
import FormMember from "../components/FormMember";
import { createEvent } from "../api/event";
import AppStore from "../stores/appStore";
import { getEventId, createEventMember } from "./../api/event";

const EventMemberCreatePage = () => {
  const { alertShow, setAlertShow, setAlertMsg, setAlertType } = AppStore();
  const { eventId } = useParams();
  const [eventData, setEventData] = useState(null);
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    let create = await createEventMember(eventId, data);
    if (create.status === true) {
      setAlertType("success");
      setAlertMsg("บันทึกข้อมูลผู้เข้าร่วมกิจกรรมเรียบร้อย");
      setAlertShow(true);
      navigate("/event/" + eventId + "/member");
    } else {
      setAlertType("error");
      setAlertMsg("error");
      setAlertShow(true);
    }
  };

  const loadEventData = async () => {
    let getEvent = await getEventId(eventId);
    if (getEvent.status === true) {
      setEventData(getEvent.data.data);
    }
  };

  useEffect(() => {
    loadEventData();
  }, []);

  return (
    <AppLayout>
      {eventData !== null && (
        <AppBreacrumb
          data={[
            { label: "กิจกรรม", link: "/event" },
            {
              label: eventData.eventName,
              link: "/event/" + eventId + "/member",
            },
            { label: "เพิ่มผู้เข้าร่วมกิจกรรม" },
          ]}
        />
      )}
      <div className="app-card">
        <div className="app-card-title">ผู้เข้าร่วมกิจกรรม</div>
        <FormMember onSubmit={onSubmit} data={null} />
      </div>
    </AppLayout>
  );
};

export default EventMemberCreatePage;
