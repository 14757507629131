import { createTheme } from "@mui/material/styles";
import { green, purple, teal } from "@mui/material/colors";

export const themeApp = createTheme({
  typography: {
    fontFamily: `"Kanit"`,
  },
  palette: {
    primary: teal,
    text: {
      primary: "#637381",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
          @font-face {
            font-family: 'Kanit';
          }
        `,
    },
  },
});
