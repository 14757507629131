import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import HomePage from "../pages/HomePage";
import SignInPage from "../pages/SignInPage";
import SignUpPage from "../pages/SignUpPage";
import SignUpSuccessPage from "../pages/SignUpSuccessPage";
import EventPage from "../pages/EventPage";
import EventCreatePage from "../pages/EventCreatePage";
import EventCreateSuccessPage from "../pages/EventCreateSuccessPage";
import EventEditPage from "../pages/EventEditPage";
import EventMemberPage from "../pages/EventMemberPage";
import EventMemberCreatePage from "../pages/EventMemberCreatePage";
import EventMemberEditPage from "../pages/EventMemberEditPage";
import EventMemberCreateSuccessPage from "../pages/EventMemberCreateSuccessPage";
import UserPage from "../pages/UserPage";
import UserCreatePage from "../pages/UserCreatePage";
import UserEditPage from "../pages/UserEditPage";
import SignOutPage from "../pages/SignOutPage";
import SettingPage from "../pages/SettingPage";
import ChangPasswordPage from "../pages/ChangPasswordPage";
import PrintTestPage from "../pages/PrintTestPage";
import ReportPage from "../pages/ReportPage";
import ReportViewPage from "../pages/ReportViewPage";
import RactTypePage from "../pages/RactTypePage";
import RactTypeCreatePage from "../pages/RactTypeCreatePage";
import RactTypeEditPage from "../pages/RactTypeEditPage";

const PrivateRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/sign-up/success" element={<SignUpSuccessPage />} />
      <Route path="/sign-out" element={<SignOutPage />} />
      <Route path="/event" element={<EventPage />} />
      <Route path="/event/create" element={<EventCreatePage />} />
      <Route path="/event/:id/edit" element={<EventEditPage />} />
      <Route
        path="/event/create/success"
        element={<EventCreateSuccessPage />}
      />
      <Route path="/event/:eventId/member" element={<EventMemberPage />} />
      <Route
        path="/event/:eventId/member/create"
        element={<EventMemberCreatePage />}
      />
      <Route
        path="/event/:eventId/member/:memberId/edit"
        element={<EventMemberEditPage />}
      />
      <Route
        path="/event/:eventId/member/create/success"
        element={<EventMemberCreateSuccessPage />}
      />
      <Route path="/user" element={<UserPage />} />
      <Route path="/user/create" element={<UserCreatePage />} />
      <Route path="/user/:userId/edit" element={<UserEditPage />} />
      <Route path="/change-password" element={<ChangPasswordPage />} />
      <Route path="/setting" element={<SettingPage />} />
      <Route path="/print-test" element={<PrintTestPage />} />
      <Route path="/report" element={<ReportPage />} />
      <Route path="/report/event/:eventId" element={<ReportViewPage />} />
      <Route path="/ract-type" element={<RactTypePage />} />
      <Route path="/ract-type/create" element={<RactTypeCreatePage />} />
      <Route path="/ract-type/:id/edit" element={<RactTypeEditPage />} />
    </Routes>
  );
};

export default PrivateRouter;
