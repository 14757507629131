import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import CancelIcon from "@mui/icons-material/Cancel";
import Stack from "@mui/material/Stack";
import UploadIcon from "@mui/icons-material/Upload";
import { useDropzone } from "react-dropzone";
import { createImportMember, getTemplateMember } from "./../../api/event";
import { WEB_ENDPOINT } from "./../../constants/endpoint";
import AppStore from "../../stores/appStore";

const ModalUpload = ({ open, setOpen, loadData, eventId }) => {
  const [f, setF] = useState(null);
  const { alertShow, setAlertShow, setAlertMsg } = AppStore();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      "text/csv": [],
      "application/vnd.ms-excel": [],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
    },
  });
  const [selectedValue, setSelectedValue] = useState("add");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const uploadTo = async () => {
    let formData = new FormData();
    formData.append("eventId", eventId);
    formData.append("dataAction", selectedValue);
    acceptedFiles.forEach((file) => {
      formData.append("file", file);
    });
    let upload = await createImportMember(eventId, formData);
    if (upload.status === true) {
      let uploadVal = upload.data.data;
      setOpen(false);
      setAlertMsg("บันทึกข้อมูลผู้เข้าร่วมกิจกรรมเรียบร้อย");
      setAlertShow(true);
      loadData();
    }
  };

  const getTemplate = async () => {
    let getFile = await getTemplateMember();
    //alert(JSON.stringify(getFile.data.data));

    setTimeout(function () {
      window.location = WEB_ENDPOINT + getFile.data.data;
    }, 1000);
  };

  const handleChange = (event) => {
    //alert(event.target.value);
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    if (open === false) {
      acceptedFiles.splice(acceptedFiles.indexOf(0), 1);
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">
          นำเข้าข้อมูลผู้ร่วมกิจกรรม
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <section className="div-upload">
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <p>คลิกเพื่อเลือกไฟล์หรือลากไฟล์วางไว้ที่นี่</p>
              </div>
            </section>
            {acceptedFiles.map((item, index) => (
              <div key={index}>ไฟล์ : {item.path}</div>
            ))}
          </Stack>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={handleChange}
            >
              <FormControlLabel
                value="add"
                control={<Radio />}
                label="เพิ่มข้อมูลใหม่"
                checked={selectedValue === "add"}
              />
              <FormControlLabel
                value="edit"
                control={<Radio />}
                label="แก้ไขข้อมูลเดิม"
                checked={selectedValue === "edit"}
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={getTemplate}
            variant="outlined"
            startIcon={<DownloadIcon />}
          >
            ตัวอย่างไฟล์
          </Button>
          <Button
            onClick={uploadTo}
            variant="contained"
            autoFocus
            disabled={acceptedFiles.length === 0 ? true : false}
            startIcon={<UploadIcon />}
          >
            อัพโหลด
          </Button>
          <Button
            onClick={handleClose}
            variant="outlined"
            color="error"
            startIcon={<CancelIcon />}
          >
            ยกเลิก
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalUpload;
