import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import AppLayout from "../components/AppLayout";
import AppBreacrumb from "../components/AppBreadcrumb";
import TbRactType from "../components/TbRactType";
import { getEventData, deleteEvent } from "../api/event";
import { getRactType, deleteRactTypeById } from "../api/master";
import EventStore from "../stores/eventStore";

const RactTypePage = () => {
  const [data, setData] = useState([]);
  const { setEvents } = EventStore();
  const navigate = useNavigate();
  const loadData = async () => {
    let getData = await getRactType();
    setData(getData.data.data);
  };
  const saveDel = async (id) => {
    await deleteRactTypeById(id);
    loadData();
  };

  useEffect(() => {
    loadData();
  }, []);
  return (
    <AppLayout>
      <AppBreacrumb
        data={[
          { label: "ตั้งค่า", link: "/setting" },
          { label: "ประเภทการแข่งขัน" },
        ]}
      />
      <div className="app-card">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <div className="app-card-title">ประเภทการแข่งขัน</div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div style={{ textAlign: "right" }}>
              {window.sessionStorage.getItem("userType") === "admin" && (
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={(e) => {
                    navigate("/ract-type/create");
                  }}
                >
                  เพิ่มประเภทการแข่งขัน
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
        <TbRactType data={data} saveDel={saveDel} />
      </div>
    </AppLayout>
  );
};

export default RactTypePage;
