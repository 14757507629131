import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { NumericFormat } from "react-number-format";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const ReportData = ({ data }) => {
  return (
    <div style={{ width: "100%" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {/*-----------------*/}
          <Grid container spacing={2}>
            <Grid item xs={6} md={6} style={{ textAlign: "center" }}>
              <Typography variant="h6" gutterBottom>
                <b>
                  <NumericFormat
                    value={20000}
                    allowLeadingZeros
                    thousandSeparator=","
                  />
                </b>
              </Typography>
              <Typography variant="h6" gutterBottom>
                ชาย
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} style={{ textAlign: "center" }}>
              <Typography variant="h6" gutterBottom>
                <b>9000</b>
              </Typography>
              <Typography variant="h6" gutterBottom>
                หญิง
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} style={{ textAlign: "center" }}>
              <Typography variant="h6" gutterBottom>
                <b>{data.pickUpY}</b>
              </Typography>
              <Typography variant="h6" gutterBottom>
                รับอุปกรณ์
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} style={{ textAlign: "center" }}>
              <Typography variant="h6" gutterBottom>
                <b>{data.pickUpN}</b>
              </Typography>
              <Typography variant="h6" gutterBottom>
                ไม่รับอุปกรณ์
              </Typography>
            </Grid>
          </Grid>
          {/*-----------------*/}
        </Grid>
      </Grid>
    </div>
  );
};

export default ReportData;
