import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppLayout from "../components/AppLayout";
import AppBreacrumb from "../components/AppBreadcrumb";
import FormPassword from "../components/FormPassword";
import { savePass } from "../api/user";
import AppStore from "../stores/appStore";

const ChangPasswordPage = () => {
  const { alertShow, setAlertShow, setAlertMsg, setAlertType } = AppStore();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    //alert(JSON.stringify(data));

    let create = await savePass(data);
    if (create.status === true) {
      setAlertType("success");
      setAlertMsg("เปลี่ยนรหัสผ่านเรียบร้อย");
      setAlertShow(true);
      //navigate("/user");
    } else {
      setAlertType("error");
      setAlertMsg(create.data);
      setAlertShow(true);
    }
  };

  return (
    <AppLayout>
      <AppBreacrumb data={[{ label: "เปลี่ยนรหัสผ่าน" }]} />
      <div className="app-card" style={{ maxWidth: 600, margin: "0 auto" }}>
        <div className="app-card-title">เปลี่ยนรหัสผ่าน</div>
        <FormPassword onSubmit={onSubmit} data={null} />
      </div>
    </AppLayout>
  );
};

export default ChangPasswordPage;
