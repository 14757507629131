import axios from "axios";
import { API_ENDPOINT } from "./../constants/endpoint";

export const authUser = async (data) => {
  try {
    let auth = await axios.post(API_ENDPOINT + "auth/user", data);
    return { status: true, data: auth.data };
  } catch (error) {
    let error_msg = "";
    if (error.response) {
      error_msg = error.response.data.msg;
    }
    return { status: false, data: error_msg };
  }
};
