import create from "zustand";

const EventStore = create((set) => ({
  events: [],
  event: {},
  members: [],
  setEvents: (data) => set((state) => ({ events: data })),
  setEvent: (data) => set((state) => ({ event: data })),
  setMembers: (data) => set((state) => ({ members: data })),
}));

export default EventStore;
