import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import AppLayout from "../components/AppLayout";
import AppBreacrumb from "../components/AppBreadcrumb";
import TbReportEvent from "../components/TbReportEvent";
import { getReportEventData } from "../api/report";
import EventStore from "../stores/eventStore";

const ReportPage = () => {
  const { setEvents } = EventStore();
  const navigate = useNavigate();
  const loadData = async () => {
    getReportEventData(setEvents);
  };
  useEffect(() => {
    loadData();
  }, []);
  return (
    <AppLayout>
      <AppBreacrumb data={[{ label: "รายงานกิจกรรม" }]} />
      <div className="app-card">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <div className="app-card-title">รายงานกิจกรรม</div>
          </Grid>
          <Grid item xs={12} sm={6}></Grid>
        </Grid>
        <TbReportEvent />
      </div>
    </AppLayout>
  );
};

export default ReportPage;
