import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import FormSignUp from "../components/FormSignUp";
import Copyright from "../components/Copyright";
import { createUser } from "../api/user";

const theme = createTheme();

const SignUpPage = () => {
  const [state, setState] = useState({ errorMsg: "" });
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    let create = await createUser(data);
    //alert(JSON.stringify(create));
    //alert(create.status);
    if (create.status === true) {
      navigate("/sign-up/success");
    } else {
      setState((result) => ({ ...result, errorMsg: create.data }));
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <DirectionsRunIcon sx={{ fontSize: 80 }} />
          <Typography component="h1" variant="h5">
            ลงทะเบียน
          </Typography>
          <Box
            sx={{
              marginTop: 5,
            }}
          >
            {state.errorMsg !== "" && (
              <Box
                sx={{
                  marginBottom: 4,
                }}
              >
                <Alert
                  severity="error"
                  onClose={() =>
                    setState((result) => ({ ...result, errorMsg: "" }))
                  }
                >
                  {state.errorMsg}
                </Alert>
              </Box>
            )}
            <FormSignUp onSubmit={onSubmit} />
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
};

export default SignUpPage;
